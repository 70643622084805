import network from '@/services/Network'
import { MeetingType, MeetingTypeEnum, NewMeetingType } from '@/types/Meeting'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true })
class MeetingModule extends VuexModule {
    private meetingState = { data: { content: new Array<MeetingType.Content>() } } as MeetingType.RootObject;
    private isLoading = true;

    @Mutation
    private setBaseState (payload: MeetingType.RootObject) {
      this.meetingState = payload
    }

    @Mutation
    private setIsLoading (loadingState: boolean): void {
      this.isLoading = loadingState
    }

    get getIsLoading (): boolean {
      return this.isLoading
    }

    get getMeetings (): MeetingType.Content[] {
      return this.meetingState.data.content
    }

    get getIncompleteMeeting (): MeetingType.Content {
      const meetings = this.meetingState.data.content
      return meetings.filter(meeting => !meeting.meeting_completed)[0]
    }

    @Action({ rawError: true })
    public async fetchMeetingList (prospect: { prospect_id: number }): Promise<void> {
      this.context.commit('setIsLoading', true)
      try {
        const { data: response } = await network().get(`/meeting/${prospect.prospect_id}`)
        const data: MeetingType.RootObject = response

        //* Apply the meeting type enum
        data.data.content.forEach(meeting => {
          meeting.meeting_type = MeetingTypeEnum[meeting.meeting_type as keyof typeof MeetingTypeEnum]
        })

        //* Set the base state
        this.context.commit('setBaseState', data)
      } catch (error) {
        // todo add some error notification or maybe snackbar
        console.log(error)
      } finally {
        this.context.commit('setIsLoading', false)
      }
    }

    @Action({ rawError: true })
    public async createNewMeeting (newMeeting: { meeting: NewMeetingType, prospect_id: number }): Promise<any> {
      this.context.commit('setIsLoading', true)
      try {
        newMeeting.meeting.meeting_type = MeetingTypeEnum[newMeeting.meeting.meeting_type as keyof typeof MeetingTypeEnum]

        const response = await network().post(`/meeting/${newMeeting.prospect_id}`, newMeeting.meeting)
        if (response.status === 201 || response.status === 200) {
          this.context.dispatch('fetchMeetingList', { prospect_id: newMeeting.prospect_id })
          return { meeting_id: response.data.data.meeting_id, error: false }
        }
      } catch (error) {
        if (error.response) {
          return { meeting_id: null, error: error.response.data.error }
        }
        return { meeting_id: null, error: error }
      } finally {
        this.context.commit('setIsLoading', false)
      }
    }

    @Action({ rawError: true })
    public async updateAMeeting (args: { prospectId: number, meetingId: number, meeting: any }): Promise<boolean> {
      const { meeting } = args
      if (meeting.meeting_type) {
        meeting.meeting_type = MeetingTypeEnum[meeting.meeting_type as keyof typeof MeetingTypeEnum]
      }

      try {
        const urlPostFix = `/meeting/${args.prospectId}/${args.meetingId}`
        const response = await network().patch(urlPostFix, meeting)
        if (response.status === 201 || response.status === 200) {
          this.context.dispatch('fetchMeetingList', { prospect_id: args.prospectId })
          return true
        }
      } catch (error) {
        return false
      }
      return true
    }
}

export default MeetingModule
