






























import { Component, Vue } from "vue-property-decorator";
import LoginForm from "../components/auth/LoginForm.vue";

@Component({
  components: {
    LoginForm,
  },
})
export default class Home extends Vue {}
