import router from '@/router'
import { SnackbarProgrammatic } from 'buefy'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import AuthService from '../services/auth/AuthService'

export declare namespace AuthResponseObject {

    export interface Authority {
        authority_id: number;
        username: string;
        authority: string;
    }

    export interface User {
        username: string;
        authorities: Authority[];
    }

    export interface Group {
        group_id: number;
        parent_id: number;
        group_name: string;
        group_thumbnail?: any;
        group_picture?: any;
        level?: any;
    }

    export interface UserDetails {
        employee_id: number;
        level: number;
        username: string;
        full_name: string;
        address: string;
        contact_no: string;
        joining_date: string;
        job_title: string;
        color: string;
        parent_id: number;
        user: User;
        group: Group;
    }

    export interface Data {
        token: string;
        user_details: UserDetails;
    }

    export interface RootObject {
        error?: any;
        timestamp: string;
        status: string;
        data: Data;
    }

}

const storedUser = localStorage.getItem('user')

@Module({ namespaced: true })
class AuthModule extends VuexModule {
    public status = storedUser ? { loggedIn: true } : { loggedIn: false };
    public user: AuthResponseObject.Data = storedUser ? JSON.parse(storedUser) : null;

    @Mutation
    public loginSuccess (user: AuthResponseObject.Data): void {
      this.status.loggedIn = true
      this.user = user
    }

    @Mutation
    public loginFailure (): void {
      this.status.loggedIn = false
      this.user = {} as AuthResponseObject.Data
    }

    @Mutation
    public logout (): void {
      this.status.loggedIn = false
      this.user = {} as AuthResponseObject.Data
    }

    get isLoggedIn (): boolean {
      return this.status.loggedIn
    }

    get userDetail (): AuthResponseObject.UserDetails {
      return this.user.user_details
    }

    get userType (): Array<string> {
      return this.user.user_details.user.authorities.map((authorityObj: { authority: any; }) => authorityObj.authority)
    }

    @Action({ rawError: true })
    async login (data: any): Promise<void> {
      try {
        const user = await AuthService.login(data.username, data.password)

        if (user === null) {
          SnackbarProgrammatic.open({
            duration: 10000,
            message: 'Username or password incorrect.',
            type: 'is-danger',
            position: 'is-bottom-right',
            actionText: 'Close',
            queue: false
          })
          this.context.commit('loginFailure')
          return
        }

        this.context.commit('loginSuccess', user)
        router.push('/dashboard/overview')
      } catch (error) {
        AuthService.logout()
        this.context.commit('loginFailure')
      }
    }

    @Action
    signOut (): void {
      AuthService.logout()
      this.context.commit('logout')
      router.push('/login')
    }
}

export default AuthModule
