import network from '@/services/Network'
import {
    KnowledgeScale,
    MeansOfKnowing,
    ProfileStatus,
    ProspectCurrentStatus,
    ProspectHistoryResponse,
    ProspectResponse,
    ProspectType,
    RiskTakingAttitudeCategories
} from '@/types/Prospect'
import { NotificationProgrammatic } from 'buefy'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

interface UrlParams {
    perPage: number;
    page: number;
    sortBy: string;
    sortDirection: string;
}

@Module({ namespaced: true })
class ProspectModule extends VuexModule {
    private prospectState = {} as ProspectResponse.RootObject;
    private prospectDetailState = {} as ProspectResponse.Content;
    private prospectHistoryState = {} as ProspectHistoryResponse.Data;
    private isLoading = true;
    private urlParams: UrlParams = {
        perPage: 25,
        page: 1,
        sortBy: 'prospect_id',
        sortDirection: 'DESC'
    };

    constructor() {
        super(VuexModule)
        this.prospectState.data = {} as ProspectResponse.Data
        this.prospectDetailState.employee = {} as ProspectResponse.Employee
    }

    @Mutation
    private setBaseState(payload: ProspectResponse.RootObject): void {
        this.prospectState = payload
    }

    @Mutation
    private setProspectDetailBaseState(payload: ProspectResponse.Content): void {
        this.prospectDetailState = payload
    }

    @Mutation
    private setProspectHistoryBaseState(payload: ProspectHistoryResponse.Data): void {
        this.prospectHistoryState = payload
    }

    @Mutation
    private setIsLoading(loadingState: boolean): void {
        this.isLoading = loadingState
    }

    @Mutation
    private normalizeProspectContent(): void {
        if (!this.prospectState.data.empty) {
            this.prospectState.data.content.forEach(prospect => {
                prospect.current_status = ProspectCurrentStatus[prospect.current_status as number]
                prospect.prospect_type = ProspectType[prospect.prospect_type as number]
                prospect.general_knowledge = KnowledgeScale[prospect.general_knowledge as number]
                prospect.business_knowledge = KnowledgeScale[prospect.business_knowledge as number]
            })
        }
    }

    @Mutation
    private setPageNumber(pageNumber: number) {
        this.urlParams.page = pageNumber
    }

    @Mutation
    private setPerPageNumber(perPage: number) {
        this.urlParams.perPage = perPage
    }

    @Mutation
    private setSortBy(sortBy: string) {
        this.urlParams.sortBy = sortBy
    }

    @Mutation
    private setSortDirection(sortDirection: string) {
        this.urlParams.sortDirection = sortDirection
    }

    get getIsLoading(): boolean {
        return this.isLoading
    }

    /**
     * The page size or the records in the current page
     */
    get getSize(): number {
        return this.prospectState.data.size
    }

    /**
     * Get page number for pagination page + 1
     */
    get getPageNumber(): number {
        return this.prospectState.data.number + 1
    }

    get getTotalPages(): number {
        return this.prospectState.data.totalPages
    }

    get getTotalElements(): number {
        return this.prospectState.data.totalElements
    }

    get getProspects(): ProspectResponse.Content[] {
        return this.prospectState.data.content
    }

    get getSortDirection(): string {
        return this.urlParams.sortDirection
    }

    get getSortBy(): string {
        return this.urlParams.sortBy
    }

    get getProspectDetail(): ProspectResponse.Content {
        return this.prospectDetailState
    }

    get getProspectHistory(): ProspectHistoryResponse.Data {
        return this.prospectHistoryState
    }

    get getProspectHistoryContent(): ProspectHistoryResponse.Content[] {
        return this.prospectHistoryState.content
    }

    @Action
    public incrementPageNumber(args: { pageNumber: number, search: string }): void {
        this.context.commit('setPageNumber', args.pageNumber)
        this.context.dispatch('fetchProspectList', { search: args.search })
    }

    @Action
    public changePerPageNumber(args: { perPage: number, search: string }): void {
        this.context.commit('setPerPageNumber', args.perPage)
        this.context.dispatch('fetchProspectList', { search: args.search })
    }

    @Action
    public changeSort(args: { sort: any, search: string }): void {
        const { sort } = args
        this.context.commit('setSortBy', sort.field)
        this.context.commit('setSortDirection', sort.order)
        this.context.dispatch('fetchProspectList', { search: args.search })
    }

    @Action({ rawError: true })
    public async fetchProspectList(args: { search: string }): Promise<void> {
        this.context.commit('setIsLoading', true)
        try {
            const constructedParams = args.search ? { ...this.urlParams, search: args.search } : this.urlParams
            const { data: response } = await network().get('/prospect', { params: constructedParams })
            const data: ProspectResponse.RootObject = response

            //* Set the base state
            this.context.commit('setBaseState', data)

            //* Normalize data - decipher the enums basically
            this.context.commit('normalizeProspectContent')
        } catch (error) {
            NotificationProgrammatic.open({
                duration: 5000,
                message: 'Could not fetch prospects. Please refresh the page.',
                position: 'is-bottom-right',
                type: 'is-danger'
            })
        } finally {
            this.context.commit('setIsLoading', false)
        }
    }

    @Action({ rawError: true })
    public async createNewProspect(newProspect: any): Promise<any> {
        try {
            newProspect.prospect_type = ProspectType[newProspect.prospect_type]
            newProspect.current_status = ProspectCurrentStatus[newProspect.current_status]
            newProspect.general_knowledge = KnowledgeScale[newProspect.general_knowledge]
            newProspect.business_knowledge = KnowledgeScale[newProspect.business_knowledge]
            newProspect.profile_status = ProfileStatus[1]
            newProspect.means_of_knowing = MeansOfKnowing[0]

            const response = await network().post('/prospect', newProspect)

            if (response.status === 201 || response.status === 200) {
                this.context.dispatch('fetchProspectList', { search: '' })
                return { prospect_id: response.data.data.prospect_id, error: false }
            }
        } catch (error) {
            if (error.response) {
                return { prospect_id: null, error: error.response.error }
            }
            return { prospect_id: null, error: error }
        }
    }

    @Action({ rawError: true })
    public async getAProspect(prospectId: number): Promise<void> {
        this.context.commit('setIsLoading', true)
        const normalizeProspectContent: Function = function (prospect: ProspectResponse.Content) {
            prospect.current_status = ProspectCurrentStatus[prospect.current_status as number]
            prospect.prospect_type = ProspectType[prospect.prospect_type as number]
            prospect.general_knowledge = KnowledgeScale[prospect.general_knowledge as number]
            prospect.business_knowledge = KnowledgeScale[prospect.business_knowledge as number]

            //* If risk taking attitude is set then look up enum
            if (prospect.risk_taking_attitude) {
                prospect.risk_taking_attitude = RiskTakingAttitudeCategories[prospect.risk_taking_attitude as number]
            }
        }

        try {
            const { data: response } = await network().get(`/prospect/${prospectId}`)

            const data: ProspectResponse.Content = response.data
            normalizeProspectContent(data)
            this.context.commit('setProspectDetailBaseState', data)
        } catch (error) {
            console.error(error)
        } finally {
            this.context.commit('setIsLoading', false)
        }
    }

    @Action({ rawError: true })
    public async getAProspectHistory(prospectId: number): Promise<void> {
        try {
            const { data: response } = await network().get(`/prospect/history/${prospectId}`)

            const data: ProspectHistoryResponse.Data = response.data

            data.content.forEach(history => {
                if (history.column_name === 'address') {
                    const oldAddress = JSON.parse(history.old_value)
                    const newAddress = JSON.parse(history.new_value)

                    history.old_value = `${oldAddress.house_no}, ${oldAddress.sector}, ${oldAddress.town.town_name}, ${oldAddress.town.city.city_name}`
                    history.new_value = `${newAddress.house_no}, ${newAddress.sector}, ${newAddress.town.town_name}, ${newAddress.town.city.city_name}`
                }
            })
            this.context.commit('setProspectHistoryBaseState', data)
        } catch (error) {
            console.error(error)
        }
    }

    @Action({ rawError: true })
    public async updateAProspect(prospect: any): Promise<boolean> {
        const denormalizeProspectContent: Function = function (prospect: ProspectResponse.Content) {
            prospect.current_status = ProspectCurrentStatus[prospect.current_status as number]
            prospect.prospect_type = ProspectType[prospect.prospect_type as number]
            prospect.general_knowledge = KnowledgeScale[prospect.general_knowledge as number]
            prospect.business_knowledge = KnowledgeScale[prospect.business_knowledge as number]

            //* If risk taking attitude is set then look up enum
            if (prospect.risk_taking_attitude) {
                prospect.risk_taking_attitude = RiskTakingAttitudeCategories[prospect.risk_taking_attitude as number]
            }
        }
        try {
            denormalizeProspectContent(prospect)
            const prospectIdReference = prospect.prospect_id
            const urlPostFix = `/prospect/${prospectIdReference}`
            delete prospect.prospect_id

            const { data: response } = await network().patch(urlPostFix, prospect)
            this.context.dispatch('getAProspectHistory', prospectIdReference)
            this.context.dispatch('getAProspect', prospectIdReference)
            return true
        } catch (error) {
            return false
        }
    }
}

export default ProspectModule
