import { AuthResponseObject } from '@/store/AuthModule'
import network from '../Network'

class AuthService {
  async login (username: string, password: string): Promise<AuthResponseObject.Data | null> {
    try {
      const { data: response } = await network().post('/user/auth/login', { username, password }, { withCredentials: false })
      const authResponse: AuthResponseObject.RootObject = response

      if (authResponse.data.token && authResponse.error === null) {
        //* Set the user token on local storage
        localStorage.setItem('user', JSON.stringify(authResponse.data))
        return authResponse.data
      } else {
        this.logout()
        throw new Error()
      }
    } catch (error) {
      this.logout()
      return null
    }
  }

  logout () {
    localStorage.removeItem('user')
  }
}

export default new AuthService()
