import network from '@/services/Network'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true })
class EmployeeModule extends VuexModule {
    private employeeList: Array<object> = [];
    private isEmployeeListEmpty = true;
    private isLoading = false;

    @Mutation
    public setEmployeeList (employeeListPayload: Array<object>): void {
      this.employeeList = employeeListPayload
    }

    @Mutation
    public setIsEmployeeListEmpty (isEmployeeListEmpty: boolean): void {
      this.isEmployeeListEmpty = isEmployeeListEmpty
    }

    @Mutation
    public setIsLoading (loadingState: boolean) {
      this.isLoading = loadingState
    }

    get getEmployeeList (): Array<object> {
      return this.employeeList
    }

    get getIsEmployeeListEmpty (): boolean {
      return this.isEmployeeListEmpty
    }

    get getIsLoading (): boolean {
      return this.isLoading
    }

    @Action({ rawError: true })
    async fetchEmployeeList (): Promise<void> {
      this.context.commit('setIsLoading', true)
      try {
        const { data: response } = await network().get('/employee')
        const { data } = response

        this.context.commit('setEmployeeList', data.content)
        this.context.commit('setIsEmployeeListEmpty', data.empty)
      } catch (error) {
        console.log(error)
      } finally {
        this.context.commit('setIsLoading', false)
      }
    }
}

export default EmployeeModule
