import { AuthResponseObject } from '@/store/AuthModule'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import initProgress from './progressbar'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Home
  },
  {
    path: '/dashboard/overview',
    name: 'Overview',
    component: (resolve) => require(['../views/dashboard/Overview.vue'], resolve)
  },
  {
    path: '/dashboard/setting',
    name: 'Setting',
    component: (resolve) => require(['../views/dashboard/Setting.vue'], resolve)
  },
  {
    //* Url: /dashboard/prospect/{prospectId}
    path: '/dashboard/prospect/:prospectId',
    name: 'ProspectDetail',
    component: (resolve) => require(['../views/dashboard/ProspectDetail.vue'], resolve)
  },
  {
    //* Url: /dashboard/prospect
    path: '/dashboard/prospect',
    name: 'Prospect',
    component: (resolve) => require(['../views/dashboard/Prospect.vue'], resolve)
  },
  {
    //* Url: /dashboard/prospect
    path: '/dashboard/report',
    name: 'Report',
    component: (resolve) => require(['../views/dashboard/Report.vue'], resolve)
  },
  {
    path: '/dashboard/admin',
    component: (resolve) => require(['../views/dashboard/admin/EmployeeManagement.vue'], resolve),
    children: [
      {
        path: 'employee-management',
        name: 'EmployeeManagement',
        component: (resolve) => require(['../views/dashboard/admin/EmployeeManagement.vue'], resolve)
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

initProgress(router)

router.beforeEach((to, from, next) => {
  const publicPages: Array<string> = ['/', '/login']
  const privilegedPages: Array<string> = ['/dashboard/report']

  const authRequired = !publicPages.includes(to.path)
  const privilegeRequired: boolean = privilegedPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  //* Trying to access a restricted page + not logged in
  //* redirect to login page
  if (authRequired && !loggedIn) {
    next('/login')
    return
  }

  if (loggedIn && privilegeRequired) {
    try {
      const user: AuthResponseObject.Data = JSON.parse(loggedIn as string)
      const isAccessible = user.user_details.user.authorities.find(authorityObj => authorityObj.authority === 'MANAGER')
      if (!isAccessible) {
        throw new Error()
      }
    } catch (error) {
      next('/')
    }
  }
  next()
})

export default router
