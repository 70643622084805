import Vue from 'vue'
import Vuex from 'vuex'

import AuthModule from './AuthModule'
import EmployeeModule from './EmployeeModule'
import ProspectModule from './ProspectModule'
import MeetingModule from './MeetingModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    AuthModule,
    EmployeeModule,
    ProspectModule,
    MeetingModule
  }
})
