import { AuthResponseObject } from '@/store/AuthModule'

/**
 * Return the auth spec header Bearer + Token
 * Check the local storage for the user object and get the token and return the object
 *
 * @returns {object} { Authorization: 'Bearer ' + user.token }
 */
export default function authHeader (): object {
  const storedUser = localStorage.getItem('user')
  const user: AuthResponseObject.Data = JSON.parse(storedUser || JSON.stringify({}))

  if (user && user.token) {
    return { Authorization: `Bearer ${user.token}` }
  } else {
    return {}
  }
}
