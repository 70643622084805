export namespace ProspectResponse {

    export interface Authority {
        authorityId: number;
        username: string;
        authority: string;
    }

    export interface User {
        username: string;
        authorities: Authority[];
    }

    export interface Group {
        group_id: number;
        parent_id: number;
        group_name: string;
        group_thumbnail?: any;
        group_picture?: any;
        level?: any;
    }

    export interface Employee {
        employee_id: number;
        level: number;
        username: string;
        full_name: string;
        address: string;
        contact_no: string;
        joining_date: string;
        job_title: string;
        color: string;
        parent_id: number;
        user: User;
        group: Group;
    }

    export interface Address {
        address_id: number;
        house_no: string;
        sector: string;
        landmark?: any;
        town: Town;
    }

    export interface Country {
        country_id: number;
        country_name: string;
    }

    export interface City {
        city_id: number;
        city_name: string;
        country: Country;
    }

    export interface Town {
        town_id: number;
        town_name: string;
        city: City;
    }

    export interface Content {
        prospect_id: number;
        prospect_name: string;
        business: string;
        projection: number;
        mobile_no: string;
        landline_no: string;
        location: string;
        address?: any;
        email: string;
        current_status: number | string;
        added_date: string;
        added_time: string;
        prospect_type?: number | string;
        financial_health?: number;
        general_knowledge?: any;
        business_knowledge?: any;
        risk_taking_attitude?: any;
        expected_margin?: number;
        trading_experience?: string;
        means_of_knowing?: any;
        profile_status: string;
        prospect_interests?: any;
        remarks?: any;
        final_closing_margin?: number | null;
        closing_date?: string | null;
        employee: Employee;
    }

    export interface Sort {
        unsorted: boolean;
        sorted: boolean;
        empty: boolean;
    }

    export interface Pageable {
        sort: Sort;
        pageNumber: number;
        offset: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
    }

    export interface Sort2 {
        unsorted: boolean;
        sorted: boolean;
        empty: boolean;
    }

    export interface Data {
        content: Content[];
        pageable: Pageable;
        totalPages: number;
        totalElements: number;
        last: boolean;
        first: boolean;
        sort: Sort2;
        size: number;
        number: number;
        numberOfElements: number;
        empty: boolean;
    }

    export interface RootObject {
        error?: any;
        timestamp: string;
        status: string;
        data: Data;
    }

}

export namespace ProspectHistoryResponse {

    export interface Content {
        prospect_history_log_id: string;
        prospect_id: number;
        change_made_by: string;
        column_name: string;
        old_value: string;
        new_value: string;
        update_date: Date;
    }

    export interface Sort {
        unsorted: boolean;
        sorted: boolean;
        empty: boolean;
    }

    export interface Pageable {
        sort: Sort;
        pageNumber: number;
        offset: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
    }

    export interface Sort2 {
        unsorted: boolean;
        sorted: boolean;
        empty: boolean;
    }

    export interface Data {
        content: Content[];
        pageable: Pageable;
        totalPages: number;
        totalElements: number;
        last: boolean;
        first: boolean;
        sort: Sort2;
        size: number;
        number: number;
        numberOfElements: number;
        empty: boolean;
    }

    export interface RootObject {
        error?: any;
        timestamp: string;
        status: string;
        data: Data;
    }

}

export enum ProspectCurrentStatus {
    Uncalled = 1,
    Low,
    Medium,
    High,
    Client
}

export enum ProspectType {
    Businessman = 1,
    'Self Employed',
    Salaried,
    Retired
}

export enum KnowledgeScale {
    'Fundamental Awareness' = 1,
    Novice,
    Intermediate,
    Advanced,
    Expert
}

export enum ProfileStatus {
    'Stage_1' = 1,
    'Stage_2',
    'Stage_3',
    'Closed'
}

export enum RiskTakingAttitudeCategories {
    'Risk Aversion' = 1,
    'Risk Seeking',
    'Risk Neutral'
}

export enum MeansOfKnowing {
    'Cold Calling',
    'Reference',
    'Generally Known',
    'Company Lead'
}
