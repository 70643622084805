import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import authHeader from './auth/auth-header'
import AuthService from './auth/AuthService'

const apiConfig = {
    returnRejectedPromiseOnError: true,
    // withCredentials: true,
    timeout: 30000,
    baseURL: 'https://presales-api.xencorps.com/api/v1',
    headers: {
        common: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
}

function globalAuthInterceptor(request: AxiosRequestConfig) {
    if (Object.keys(authHeader()).length !== 0) {
        request.headers = authHeader()
    }
    return request
}

function globalSuccessHandler(response: AxiosResponse) {
    return response
}

function globalErrorHandler(err: any) {
    if (err.response.status === 401) {
        AuthService.logout()
        window.location.reload()
    }
    return Promise.reject({ ...err })
}

export default function network() {
    const axios = Axios.create(apiConfig)

    axios.interceptors.request.use(request => globalAuthInterceptor(request))
    axios.interceptors.response.use(globalSuccessHandler, globalErrorHandler)
    return axios
}
