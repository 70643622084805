







import { Component, Vue } from "vue-property-decorator";

// @ts-ignore
import network from "@/services/Network";
import { DateTime } from "luxon";
import { SnackbarProgrammatic as Snackbar } from "buefy";
import router from "./router";

// @ts-ignore
import followupNotificationSound from "@/assets/follow_up_notification.mp3";

@Component
export default class Home extends Vue {
  created(): void {
    setInterval(async function () {
      const response = await network().get("/followup/schedule?query=today");
      const { data } = response;
      const followUps = data.data.content;
      if (followUps.length === 0) {
        return;
      }
      followUps.forEach(async (followUp: any) => {
        const minute = DateTime.fromSQL(followUp.follow_up_time).diffNow(
          "minute"
        ).minutes;
        if (minute < 2 && minute > 0) {
          await new Audio(followupNotificationSound).play();
          Snackbar.open({
            type: "is-primary",
            queue: false,
            duration: 10 * 1000,
            position: "is-bottom-right",
            actionText: "Go to Prospect",
            message: `Follow up ${followUp.prospect.prospect_name} ${
              Math.trunc(minute) === 0
                ? "Now."
                : "in " + Math.trunc(minute).toString() + " Minutes."
            }`,
            onAction: () => {
              router.push(
                `/dashboard/prospect/${followUp.prospect.prospect_id}`
              );
            },
          });
        }
      });
    }, 60 * 1000);
  }
}
