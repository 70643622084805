import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const Auth = namespace('AuthModule')

@Component({
  name: 'LoginForm'
})
export default class LoginForm extends Vue {
    private user: any = { username: '', password: '' };
    private message = '';
    private isLoading = false;

    @Auth.Getter
    private isLoggedIn!: boolean;

    @Auth.Action
    private login!: (data: any) => Promise<any>;

    created () {
      if (this.isLoggedIn) {
        this.$router.push('/dashboard/overview')
      }
    }

    async handleLogin () {
      this.isLoading = true
      if (this.user.username && this.user.password) {
        try {
          await this.login(this.user)
        } catch (error) {
          this.message = error
        } finally {
          this.isLoading = false
        }
      } else {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: !this.user.username ? 'Username cannot be empty.' : 'Password cannot be empty.',
          type: 'is-danger',
          position: 'is-bottom-right',
          actionText: 'Close',
          queue: false
        })
      }
      this.isLoading = false
    }
}
